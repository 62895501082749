import { getUniversalBbxCookie } from '@wh/common/chapter/types/cookies'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { NextRequest } from '@wh/common/chapter/types/nextJS'
import { useCallback, useState } from 'react'

export type ViewMode = 'list' | 'grid'

const DAYS_IN_MS = 1000 * 60 * 60 * 24
const expiresInSevenDays = new Date(new Date().getTime() + DAYS_IN_MS * 7)

export const getInitialViewMode = (searchResult: SearchResult, req: NextRequest | undefined): ViewMode => {
    const viewModeFromBackend = searchResult?.metaData?.viewMode === 'GRID_VIEW' ? 'grid' : 'list'

    const viewModeFromCookie = getUniversalBbxCookie('bbxResultListViewMode', req)
    return (viewModeFromCookie ?? viewModeFromBackend) as ViewMode
}

export const useViewMode = (initialViewMode: ViewMode): [ViewMode, (newViewMode: ViewMode, options: { setCookie: boolean }) => void] => {
    const [viewMode, setViewModeInternal] = useState<ViewMode>(initialViewMode)

    const setViewMode = useCallback((newViewMode: ViewMode, options: { setCookie: boolean }) => {
        if (options.setCookie) {
            document.cookie = `bbxResultListViewMode=${newViewMode};path=/;expires=${expiresInSevenDays.toUTCString()}`
            setViewModeInternal(newViewMode)
        } else {
            const viewModeFromCookie = getUniversalBbxCookie('bbxResultListViewMode', undefined)
            if (!viewModeFromCookie) {
                setViewModeInternal(newViewMode)
            }
        }
    }, [])

    return [viewMode, setViewMode]
}
