import { TrustProfile, TrustSignals } from '../TrustProfile'
import { ApiError, wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { apiErrorFromFetchResponse } from '@wh/common/chapter/api/fetcher'

const getTrustProfile = async (trustProfileLink?: ContextLink): Promise<TrustProfile> => {
    if (!trustProfileLink) {
        return Promise.reject(new ApiError('trustProfileLink missing'))
    }

    try {
        const controller = new AbortController()
        const timeoutId = setTimeout(() => controller.abort(), 2000)

        const url = trustProfileLink.uri
        const response = await fetch(url, {
            headers: {
                'X-Trust-SDK': JSON.stringify({
                    appName: 'willhaben.at',
                    sdkName: 'WEB',
                    sdkVersion: '-',
                    sdkPlatform: 'WEB',
                    platformName: 'WEB',
                }),
            },
            signal: controller.signal,
        })
        clearTimeout(timeoutId)

        if (!response.ok) {
            // gracefully ignore json deserialization errors for error responses to get a proper ApiError
            const errorJson = (await response.json().catch(() => undefined)) as unknown
            throw apiErrorFromFetchResponse(undefined, response, errorJson, url)
        }
        return response.json() as Promise<TrustProfile>
    } catch (error) {
        throw wrapInApiErrorIfNecessary(error)
    }
}

export const getFormattedTrustSignals = async (trustProfileLink: ContextLink | undefined): Promise<TrustSignals | undefined> => {
    try {
        const { presence, badges, communication, reputation } = await getTrustProfile(trustProfileLink)
        return {
            presenceText: presence.presenceText,
            replyTime: communication.replyTimeText,
            payliverySales: badges.find(({ id }) => id === 'paylivery-seller')?.amount,
            reputation,
        }
    } catch (e) {
        console.error('TrustProfile was not found', e)
        return undefined
    }
}
