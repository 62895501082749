import { Box, BoxProps } from '@wh-components/core/Box/Box'
import React, { FunctionComponent } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import TooltipQuestionmark from '@wh-components/icons/TooltipQuestionmark'
import { Tooltip } from '@wh-components/core/Tooltip/Tooltip'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'

const toolTipText =
    'PayLivery-Bewertungen können von allen Usern abgegeben werden, die mittels willhaben-PayLivery eine Transaktion auf willhaben durchführen.'
export const PrivateProfilePayLiveryRatingsText: FunctionComponent<{ ratingsCount: number; showTooltip?: boolean } & BoxProps> = ({
    ratingsCount,
    showTooltip = true,
    ...props
}) => {
    return (
        <Box testId="bap-paylivery-trust-text" marginTop="s" marginBottom={-1} {...props}>
            <Text as="p" fontSize="xs" color="palette.raccoon" display="flex">
                {ratingsCount} Bewertung{ratingsCount > 1 ? 'en' : ''} über PayLivery
                {showTooltip && (
                    <Box paddingLeft="xs">
                        <Tooltip
                            interactive={true}
                            content={
                                <span>
                                    {toolTipText}
                                    <br />
                                    <ServerRoutingAnchorLink
                                        type="anchor"
                                        target="_blank"
                                        href="https://hilfe.willhaben.at/hc/de/articles/360021552859-Kann-ich-eine-PayLivery-Transaktion-bewerten-"
                                    >
                                        Mehr Informationen
                                    </ServerRoutingAnchorLink>
                                </span>
                            }
                        >
                            <Box display="flex">
                                <TooltipQuestionmark size={16} />
                            </Box>
                        </Tooltip>
                    </Box>
                )}
            </Text>
        </Box>
    )
}
