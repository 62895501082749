import React, { FunctionComponent, useState } from 'react'
import copy from 'clipboard-copy'
import { objectToGetParams } from '@wh/common/chapter/lib/urlHelpers'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { ActionSheet } from '@wh-components/core/ActionSheet/ActionSheet'
import { DropdownItemType } from '@wh-components/core/Dropdown/Dropdown'
import EmailIcon from '@wh-components/icons/Email'
import WhatsAppIcon from '@wh-components/icons/SocialWhatsapp'
import FacebookIcon from '@wh-components/icons/SocialFacebook'
import CopyLinkIcon from '@wh-components/icons/CopyLink'
import TwitterIcon from '@wh-components/icons/SocialTwitter'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import LinkedInIcon from '@wh-components/icons/SocialLinkedin'

export type ShareButtonActionType = 'email' | 'whatsapp' | 'facebook' | 'twitter' | 'linkedin' | 'copylink'

interface ShareButtonProps {
    bodyText: string
    shareText?: string
    url: string
    adId?: string
    taggingData: TaggingData
    shareWebShareApiEvent?: TaggingActionEvent
    shareEmailEvent?: TaggingActionEvent
    shareWhatsAppEvent?: TaggingActionEvent
    shareFacebookEvent?: TaggingActionEvent
    shareTwitterEvent?: TaggingActionEvent
    shareLinkedInEvent?: TaggingActionEvent
    shareLinkEvent?: TaggingActionEvent
    shownShareActionTypes?: ShareButtonActionType[]
    children: (toggle: () => void) => JSX.Element
}

export const ShareButton: FunctionComponent<ShareButtonProps> = ({
    bodyText,
    url,
    shareText = 'Will teilen:',
    adId,
    taggingData,
    shareWebShareApiEvent,
    shareEmailEvent,
    shareWhatsAppEvent,
    shareFacebookEvent,
    shareTwitterEvent,
    shareLinkedInEvent,
    shareLinkEvent,
    shownShareActionTypes = ['email', 'whatsapp', 'facebook', 'twitter', 'copylink'],
    children,
}) => {
    const [open, setOpen] = useState(false)
    const standardShareText = `${shareText} ${bodyText}\r\n\r\n${url}`

    const emailShareAction = {
        label: 'E-Mail',
        Icon: <EmailIcon size="small" marginRight="xs" />,
        testId: 'share-via-email',
        onClick: () => {
            windowOpen(`mailto:${objectToGetParams({ subject: 'Will teilen!', body: standardShareText })}`)
            if (shareEmailEvent) {
                callActionEvent(shareEmailEvent, taggingData)
            }
            logAdSharedBDSEvent(adId, 'email')
        },
    }
    const whatsappShareAction = {
        label: 'WhatsApp',
        Icon: <WhatsAppIcon size="small" color="socialMedia.whatsapp" marginRight="xs" />,
        testId: 'share-via-whatsapp',
        onClick: () => {
            windowOpen(
                `https://api.whatsapp.com/send${objectToGetParams({
                    text: standardShareText,
                })}`,
            )
            if (shareWhatsAppEvent) {
                callActionEvent(shareWhatsAppEvent, taggingData)
            }
            logAdSharedBDSEvent(adId, 'whatsapp')
        },
    }
    const facebookShareAction = {
        label: 'Facebook',
        Icon: <FacebookIcon size="small" color="socialMedia.facebook" marginRight="xs" />,
        testId: 'share-via-facebook',
        onClick: () => {
            windowOpen(
                `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
                    u: url,
                    quote: standardShareText,
                })}`,
            )
            if (shareFacebookEvent) {
                callActionEvent(shareFacebookEvent, taggingData)
            }
            logAdSharedBDSEvent(adId, 'facebook')
        },
    }
    const twitterShareAction = {
        label: 'X (bisher Twitter)',
        Icon: <TwitterIcon size="small" color="socialMedia.twitter" marginRight="xs" />,
        testId: 'share-via-twitter',
        onClick: () => {
            windowOpen(
                `https://twitter.com/share${objectToGetParams({
                    url: url,
                    text: `${shareText} ${bodyText}\r\n\r\n`,
                })}`,
            )
            if (shareTwitterEvent) {
                callActionEvent(shareTwitterEvent, taggingData)
            }
            logAdSharedBDSEvent(adId, 'twitter')
        },
    }
    const linkedInShareAction = {
        label: 'LinkedIn',
        Icon: <LinkedInIcon size="small" color="socialMedia.linkedin" marginRight="xs" />,
        testId: 'share-via-linkedin',
        onClick: () => {
            windowOpen(
                `https://linkedin.com/shareArticle${objectToGetParams({
                    url: url,
                })}`,
            )
            if (shareLinkedInEvent) {
                callActionEvent(shareLinkedInEvent, taggingData)
            }
            logAdSharedBDSEvent(adId, 'linkedin')
        },
    }
    const copyLinkShareAction = {
        label: 'Link kopieren',
        Icon: <CopyLinkIcon size="small" marginRight="xs" />,
        testId: 'copy-link',
        onClick: () => {
            copy(url)
            if (shareLinkEvent) {
                callActionEvent(shareLinkEvent, taggingData)
            }
        },
    }

    const shareActions: DropdownItemType[] = shownShareActionTypes?.map((type: ShareButtonActionType) => {
        switch (type) {
            case 'email':
                return emailShareAction
            case 'whatsapp':
                return whatsappShareAction
            case 'facebook':
                return facebookShareAction
            case 'twitter':
                return twitterShareAction
            case 'linkedin':
                return linkedInShareAction
            case 'copylink':
                return copyLinkShareAction
        }
    })

    /**
     * Try to show a sharing dialog using the Web Share API.
     * If that fails, return a failed promise (except for errors that happened _after_ the sharing dialog appeared, like the user cancelled, or the target application responded with an error)
     */
    const tryNavigatorShare = async (): Promise<void> => {
        if (!navigator.share) {
            throw Error('web share api not supported by browser')
        }

        return navigator.share({ text: `${shareText} ${bodyText}`, url }).catch((error) => {
            if (error.name === 'AbortError' || error.name === 'DataError') {
                // ignore these errors and do not fall back to the old share dialog
                return
            }
            throw error
        })
    }

    return (
        <ActionSheet items={shareActions} isOpen={open} onRequestClose={() => setOpen(false)} dropdownPlacement="bottom-end">
            {children(() => {
                const newOpenState = !open
                if (newOpenState) {
                    tryNavigatorShare()
                        .then(() => {
                            if (shareWebShareApiEvent) {
                                callActionEvent(shareWebShareApiEvent, taggingData)
                            }
                        })
                        .catch(() => {
                            // if sharing with the Web Share API failed, fall back to showing the old sharing options by opening the action sheet
                            setOpen(newOpenState)
                        })
                } else {
                    setOpen(newOpenState)
                }
            })}
        </ActionSheet>
    )
}

const logAdSharedBDSEvent = (adId: string | undefined, medium: string) => {
    if (adId) {
        logBdsEvent(adId, 'atz-shared', 'medium', medium)
    }
}

// adapted from: https://github.com/nygardk/react-share/blob/master/src/utils/createShareButton.jsx
const windowOpen = (url: string, name = 'sharedialog', height = 400, width = 550) => {
    const left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2
    const top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2

    const config = {
        height,
        width,
        left,
        top,
        location: 'no',
        toolbar: 'no',
        status: 'no',
        directories: 'no',
        menubar: 'no',
        scrollbars: 'yes',
        resizable: 'no',
        centerscreen: 'yes',
        chrome: 'yes',
    }

    window.open(
        url,
        name,
        Object.entries(config)
            .map(([key, value]) => `${key}=${value}`)
            .join(', '),
    )
}
