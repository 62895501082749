import { Request } from 'express'
import { FollowerProfile, SellerProfileWrapper } from '@bbx/search-journey/common/SellerProfile'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { encodeURIifNecessary } from '@wh/common/chapter/lib/urlHelpers'
import { fetcher } from '@wh/common/chapter/api/fetcher'
import { urlSearchParamsFromObject } from '@wh/common/chapter/api/urlSearchParams'

export const getSellerProfile = (
    loginId: string,
    verticalId: number,
    additionalParams?: Record<string, string | string[]>,
    request?: Request,
): Promise<SellerProfileWrapper> => {
    return fetcher<SellerProfileWrapper>(
        `/iad/sellerprofile/${loginId}/${verticalId}/profile?${additionalParams ? urlSearchParamsFromObject(additionalParams) : ''}`,
        {
            credentials: 'include',
            cookies: getBbxCookiesFromRequest(request),
        },
    )
}

export const getFollowerProfile = (loginId: string, verticalId: number): Promise<FollowerProfile> => {
    return fetcher<FollowerProfile>(`/iad/sellerprofile/${loginId}/${verticalId}/follower`, {
        credentials: 'include',
    })
}
/** Unfollow a seller (user) */
export const unfollowUserWithLink = (contextLink: ContextLink, request?: Request): Promise<void> => {
    return fetcher(`/${contextLink.serviceName}${encodeURIifNecessary(contextLink.relativePath!)}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    })
}
