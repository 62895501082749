import { BapResultListUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { getAlertSearchResult, getSeoSearchResult } from '@bbx/search-journey/common/api/searchApiClient'
import { getSellerProfile } from '@bbx/search-journey/common/api/sellerProfileApiClient'
import { getFormattedTrustSignals } from '@bbx/search-journey/common/api/trustProfileApiClient'
import { getInitialViewMode, useViewMode, ViewMode } from '@bbx/search-journey/common/lib/viewModeHelper'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { SellerProfileWrapper } from '@bbx/search-journey/common/SellerProfile'
import { TrustSignals } from '@bbx/search-journey/common/TrustProfile'
import { SellerProfileContainer } from '@bbx/search-journey/sub-domains/search/components/common/lead-journey/SellerProfile/SellerProfileContainer'
import { DesktopBreadcrumbs } from '@bbx/search-journey/sub-domains/search/components/common/result-list/DesktopBreadcrumbs/DesktopBreadcrumbs'
import { BapResultListContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/BapResultListContainer'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { SkyscraperLayout } from '@wh/common/chapter/components/Layouts/SkyscraperLayout'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { concatPathWithQueryParams, getFirstString } from '@wh/common/chapter/lib/urlHelpers'
import { ExpressNextContextWithUserData } from '@wh/common/chapter/types/nextJS'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { NextPage } from 'next'
import React, { Fragment } from 'react'

export type SellerProfileProps =
    | {
          searchResult: SearchResult
          sellerProfileLoginId: string
          verticalId: number
          sellerProfileWrapper: SellerProfileWrapper
          defaultViewMode: ViewMode
          trustSignals?: TrustSignals
      }
    | {
          is404: true
          defaultViewMode: ViewMode
      }

export const SellerProfilePage: NextPage<SellerProfileProps> & { Layout: typeof Layout } = (props) => {
    const [viewMode, setViewMode] = useViewMode(props.defaultViewMode)

    if ('is404' in props) {
        return <ErrorPage statusCode={404} />
    } else {
        return (
            <Fragment>
                <BapResultListUserZoom />
                <DesktopBreadcrumbs breadcrumbs={breadcrumbs} />
                <SellerProfileContainer
                    sellerProfileLoginId={props.sellerProfileLoginId}
                    verticalId={props.verticalId}
                    sellerProfile={props.sellerProfileWrapper.sellerProfile}
                    contextLinkList={props.sellerProfileWrapper.contextLinkList.contextLink}
                    initialFollowerProfile={props.sellerProfileWrapper.sellerFollowerDTO}
                    shouldShowPayliveryRatings={true}
                    taggingData={props.searchResult.taggingData}
                    trustSignals={props.trustSignals}
                    searchAgentOptionsLink={findContextLinkWithIdFromArray(
                        'searchAgentOptionsLink',
                        props.searchResult.searchContextLinks.contextLink,
                    )}
                />
                <BapResultListContainer
                    searchResult={props.searchResult}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    pageType="seller-profile"
                    pageViewEvent="seller_profile"
                />
            </Fragment>
        )
    }
}

export const sellerProfileGetInitialProps =
    (verticalId: number, basePath: string) =>
    async ({ query, req, res }: ExpressNextContextWithUserData): Promise<SellerProfileProps> => {
        const { seopath, alertId, ...remainingQuery } = query
        const { sellerProfileLoginId, path } = extractPathAndId(seopath, basePath)
        const searchAgentId = getFirstString(alertId)

        if (!sellerProfileLoginId) {
            if (res) {
                res.statusCode = 404
            }
            return { is404: true, defaultViewMode: 'list' }
        }
        try {
            const concatenatedPath = concatPathWithQueryParams(path, { ...remainingQuery })
            const [sellerProfile, searchResult] = await Promise.all([
                getSellerProfile(sellerProfileLoginId, verticalId, remainingQuery as Record<string, string>, req),
                searchAgentId ? getAlertSearchResult(query, req) : getSeoSearchResult(concatenatedPath, req),
            ])

            const trustSignals = await getFormattedTrustSignals(
                findContextLinkWithIdFromArray('trustProfileLink', sellerProfile.contextLinkList.contextLink),
            )

            const defaultViewMode = getInitialViewMode(searchResult, req)
            return { sellerProfileWrapper: sellerProfile, sellerProfileLoginId, verticalId, searchResult, defaultViewMode, trustSignals }
        } catch (error) {
            const apiError = wrapInApiErrorIfNecessary(error)

            if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
                if (res) {
                    res.statusCode = 404
                }
                return { is404: true, defaultViewMode: 'list' }
            }

            throw apiError
        }
    }

const extractPathAndId = (seopath: string | string[] | undefined, basePath: string) => {
    let sellerProfileLoginId
    let path
    if (typeof seopath === 'string') {
        sellerProfileLoginId = seopath
        path = [basePath, seopath].join('/')
    } else {
        sellerProfileLoginId = seopath?.[0]
        path = [basePath, ...(seopath ?? [])].join('/')
    }

    return { sellerProfileLoginId, path }
}

const breadcrumbs = [
    { displayName: 'Startseite', seoUrl: staticRelativeCanonicals.Home },
    { displayName: 'Marktplatz', seoUrl: staticRelativeCanonicals.BapHome },
    { displayName: 'Öffentliches Profil', seoUrl: '' },
]

SellerProfilePage.getInitialProps = sellerProfileGetInitialProps(verticalIdMap.BAP, '/kaufen-und-verkaufen/verkaeuferprofil')
SellerProfilePage.Layout = SkyscraperLayout

export default SellerProfilePage
