import React from 'react'
import { Modal } from '@wh-components/core/Modal/Modal'
import { Button } from '@wh-components/core/Button/Button'
import { Box } from '@wh-components/core/Box/Box'
import { ResponsiveGuaranteedValue } from '@wh-components/system'
import { Divider } from '@wh-components/core/Divider/Divider'
import { useResponsiveGuaranteedValueWithSSRFallback } from '@wh-components/core/utilities/responsive'
import { useCreateResponsiveGuaranteedValueWithSSRFallback } from '../../UserAgentProvider/useUserAgent'

interface ConfirmModalProps {
    isOpen: boolean
    onRequestClose: () => void
    header: string
    onAbort: () => void
    onConfirm: () => void
    confirmButtonText: string
    abortButtonText: string
    fullScreen?: ResponsiveGuaranteedValue<boolean>
    children: React.ReactNode
    testId?: string
}

export const ConfirmModal = ({
    isOpen,
    onRequestClose,
    header,
    onAbort,
    onConfirm,
    confirmButtonText,
    abortButtonText,
    fullScreen,
    children,
    testId,
}: ConfirmModalProps) => {
    // avoid flickering on appearance on mobile
    const fullScreenResponsiveValue = useCreateResponsiveGuaranteedValueWithSSRFallback(fullScreen ?? false)
    const fullScreenValue = useResponsiveGuaranteedValueWithSSRFallback(fullScreenResponsiveValue)

    return (
        <Modal testId={testId} header={header} isOpen={isOpen} onRequestClose={onRequestClose} fullScreen={fullScreenValue}>
            <Box marginBottom="m">{children}</Box>
            <Divider marginBottom="m" />
            <Box display="flex" justifyContent="space-between" flexDirection={{ phone: 'column-reverse', tablet: 'row' }}>
                <Button
                    disabled={false}
                    onClick={onAbort}
                    color="complimentary"
                    name="abort"
                    testId="modalAbortButton"
                    marginBottom={{ phone: 'm', tablet: 'inherit' }}
                >
                    {abortButtonText}
                </Button>

                <Button
                    disabled={false}
                    onClick={onConfirm}
                    name="confirm"
                    testId="modalConfirmButton"
                    marginBottom={{ phone: 'm', tablet: 'inherit' }}
                >
                    {confirmButtonText}
                </Button>
            </Box>
        </Modal>
    )
}
