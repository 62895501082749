import React from 'react'
import { css } from 'styled-components'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'

type ReportSellerProfileWithModalProps = {
    userId: string
    verticalId: number
}

export const ReportUserProfileButton: React.FunctionComponent<ReportSellerProfileWithModalProps> = ({ userId, verticalId }) => {
    return (
        <ServerRoutingAnchorLink
            href={`/iad/reportprofile?userId=${userId}&vertical=${verticalId}`}
            type="anchor"
            css={css`
                font-size: ${(p) => p.theme.fontSizes.s};
                font-weight: ${(p) => p.theme.fontWeights.bold};
                color: ${(p) => p.theme.colors.palette.raccoon};
                padding: 0 ${(p) => p.theme.space.s}px;
                margin-right: -${(p) => p.theme.space.s}px;
                display: flex;
                align-items: center;
                &:hover {
                    text-decoration: none;
                }
            `}
            aria-label="Zur Profil melden Seite"
        >
            Melden
        </ServerRoutingAnchorLink>
    )
}
