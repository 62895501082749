import React, { FunctionComponent } from 'react'
import { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { LayoutProps } from '@wh/common/chapter/components/Layouts/LayoutProps'

type SkyscraperLayoutProps = Omit<LayoutProps, 'showLeaderboard' | 'showSkyscraper'>

export const SkyscraperLayout: FunctionComponent<SkyscraperLayoutProps> & { appEmbedded: false } = (props) => (
    <Layout {...props} showLeaderboard={false} showSkyscraper={true} />
)

SkyscraperLayout.appEmbedded = false
